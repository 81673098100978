<template>
  <div class="login d-flex align-center" style="height: 100vh; background-color:white;">
    <form class="login-section pa-3" @submit.prevent="login">
      <div class="login login-container">
        <div>
          <div class="login-logo-style mb-4">
            <v-img max-width="340px" :src="require(`../assets/${getLogoName()}`)"></v-img>
            <p class="ma-0 pa-0 grey--text pt-2">
              {{ translations.activityPlanner }}
            </p>
          </div>

          <h1 class="login-title mr-2">{{ translations.login }}</h1>
          <v-alert color="error" text v-if="errorMsg" dense style="font-size: 13px">
            {{ errorMsg }}
          </v-alert>
        </div>
        <label class="f-14" for="username">{{ translations.username }}</label>
        <v-text-field v-model.trim="username" name="username" outlined hide-details class="mb-5"></v-text-field>
        <label class="f-14" for="password">{{ translations.password }}</label>
        <v-text-field v-model="password" name="password" outlined hide-details type="password"></v-text-field>
        <v-checkbox v-model="rememberMe" :label="translations.rememberMe"></v-checkbox>
      </div>
      <div class="login-btn-container">
        <v-btn :loading="loading" block color="main" dark depressed type="submit">{{ translations.login }}</v-btn>
      </div>
    </form>
    <section class="construction-background" style="align-self: center;"></section>

    <!-- version -->
    <div class="version">{{ version }}</div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { auth } from '@/utils/api'
import { onLogin } from '@/vue-apollo'
import { translations } from '@/utils/common'
import { camelCase } from 'lodash'
import userInfo from '@/graphql/UserInfo.gql'
import { getLogoFilename } from '@/utils/api/config.js'
import store from '../store'
import { initCommonData } from '@/main'

export default {
  name: 'Login',
  data: () => ({
    username: '',
    password: '',
    errorMsg: '',
    rememberMe: false,
    loading: false
  }),

  created() {
    const username = localStorage.getItem('username')
    this.username = username || ''
    if (this.username) {
      this.rememberMe = true
    }
  },

  methods: {
    getLogoName() {
      return getLogoFilename()
    },

    ...mapActions(['setUserInfo']),

    async login() {
      try {
        this.errorMsg = ''
        this.loading = true
        const token = await auth.login(this.username, this.password)
        await onLogin(this.$apollo.provider.defaultClient, token)
        if (this.rememberMe) {
          localStorage.setItem('username', this.username)
        } else {
          localStorage.removeItem('username')
        }

        // get user data
        const userInfo = await this.getUserInfo()

        // init common data
        initCommonData(store)

        // move to main route
        this.loading = false
        return this.$router.push({ name: userInfo?.account?.capabilities?.length > 0 ? 'Dashboard' : 'ReferTo' })
      } catch (e) {
        this.loading = false
        this.errorMsg = translations[camelCase(e.response.data.message)]
      }
    },

    async getUserInfo() {
      const res = await this.$apollo.query({ query: userInfo })
      this.setUserInfo(res.data.userInfo)

      return res.data.userInfo
    }
  },

  computed: {
    translations: () => translations,

    ...mapState({
      version: state => 'v ' + state?.clientVersion?.clientVersion
    })
  }
}
</script>

<style>
.login .v-text-field .v-input__control .v-input__slot {
  min-height: 40px !important;
  display: flex !important;
  align-items: center !important;
}

.login .v-textarea textarea {
  line-height: 18px !important;
}

.login .v-textarea textarea[readonly="readonly"] {
  color: #a6a6a6;
}

.login:not(.v-select).v-text-field input[readonly="readonly"] {
  color: #a6a6a6;
}

.construction-background {
  background-image: url("../assets/construction-worker.jpg");
  background-position: center;
  background-size: cover;
  width: 50%;
  height: 100%;
  display: none;
}

.f-14 {
  font-size: 14px;
}

.login-logo-style {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.login-section {
  width: 100%;
}

.login-container {
  width: 400px;
  margin: 0 auto;
}

.login-title {
  font-size: 25px;
}

.login-btn-container {
  width: 400px;
  margin: 0 auto;
}

@media screen and (min-width: 840px) {
  .construction-background {
    display: block;
  }

  .login-section {
    width: 50%;
  }
}

@media screen and (min-width: 1086px) {
  .construction-background {
    width: 60%;
  }

  .login-section {
    width: 40%;
  }
}

.version {
  position: absolute;
  bottom: 3px;
  right: 6px;
  font-size: small;
  text-shadow: -1px -1px 2px white, 1px 1px 2px white;
}
</style>
